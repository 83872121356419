import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material-module/material-module.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { GameComponent } from './game/game.component';
import { ClueComponent } from './clue/clue.component';
import { TimerComponent } from './timer/timer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';

import { routing } from './app.routing';
import { LogosvgComponent } from './logosvg/logosvg.component';
import { CodeInputComponent } from './code-input/code-input.component';
import { ClueListComponent } from './clue-list/clue-list.component';
import { FinalAnswerComponent } from './final-answer/final-answer.component';
import { GameTimerComponent } from './game-timer/game-timer.component';
import { ConfirmNextCardComponent } from './confirm-next-card/confirm-next-card.component';
import { GameLostComponent } from './game-lost/game-lost.component';
import { VerifyZohoComponent } from './verify-zoho/verify-zoho.component';

@NgModule({
  declarations: [
    AppComponent,
    GameComponent,
    ClueComponent,
    TimerComponent,
    HeaderComponent,
    HomeComponent,
    LogosvgComponent,
    CodeInputComponent,
    ClueListComponent,
    FinalAnswerComponent,
    GameTimerComponent,
    ConfirmNextCardComponent,
    GameLostComponent,
    VerifyZohoComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    routing
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmNextCardComponent
  ]
})
export class AppModule { }
