import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GamestateGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var groupId = parseInt(localStorage.getItem("group")) - 1;
        if (parseInt(localStorage.getItem('gametimer'+groupId))<=0) {
            // logged in so return true
            this.router.navigate(['/game-lost']);
            return false;
        }

        // not logged in so redirect to login page with the return url
        return true;
    }
}
