import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-confirm-next-card',
  templateUrl: './confirm-next-card.component.html',
  styleUrls: ['./confirm-next-card.component.css']
})
export class ConfirmNextCardComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data)
 }

  ngOnInit() {
  }

}
