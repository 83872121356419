import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs/observable";
import { TimerService } from '../timer.service'

@Component({
  selector: 'app-clue-list',
  templateUrl: './clue-list.component.html',
  styleUrls: ['./clue-list.component.css']
})
export class ClueListComponent implements OnInit {

  clueList : any = []
  groupId: any;

  constructor(timerService: TimerService) {
    this.groupId = parseInt(localStorage.getItem("group")) - 1;

    this.restoreSession();

    timerService.getObservable().subscribe(clue => {
      if (clue instanceof Object) {
        if (clue.clue) {
          this.clueList.push(clue)
          localStorage.setItem("clueList"+this.groupId, JSON.stringify(this.clueList))
        }
      }
    })
    timerService.getClearObservable().subscribe(clear=> {
      if (clear.clear) {
        this.clueList = []
        localStorage.setItem("clueList"+this.groupId, "");
      }
    })
  }

  ngOnInit() {

  }

  restoreSession() {
    var cluelist = localStorage.getItem("clueList"+this.groupId)
    if (!cluelist || cluelist == "")
      this.clueList = []
    else
      this.clueList = JSON.parse(cluelist)
  }

}
