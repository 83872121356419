import { Component, OnInit } from '@angular/core';
import { CodeService } from '../code.service'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss']
})
export class CodeInputComponent implements OnInit {
  public code : any;

  constructor(private router: Router, private codeService: CodeService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.codeService.isValidCode(this.code)) {
      var oldGroup = this.codeService.getGroup();
      this.codeService.setCode(this.code);
      if (this.codeService.getGroup() != oldGroup) {
        this.resetSession();
      }
      this.router.navigate(['game'])
    }
    else
      this.toastr.error('Código invalido', '', {
        positionClass: 'toast-bottom-right'
      })
  }

  resetSession() {
    localStorage.removeItem("gametimer")
    localStorage.removeItem("clueList")
    localStorage.removeItem("timer")
    localStorage.removeItem("showsButton")
    localStorage.removeItem("currentCard")
    localStorage.removeItem("currentClue")
  }

}
