var groups = [
  {
    group: 1,
    cards: [1,2,3,4,5,6,7,8,9,10,11],
    times: {
      firstClue: {
        time: 4,
        format: "04:00"
      },
      nextClue: {
        time:2,
        format:"02:00"
      },
      totalTime: 11
    }
  },
  {
    group: 2,
    cards: [1,8,6,7,12,2,3,4,5,9,10,11],
    times: {
      firstClue: {
        time:4,
        format:"04:00"
      },
      nextClue: {
        time:2,
        format:"02:00"
      },
      totalTime: 11
    }

  },
  {
    group: 3,
    cards: [1,6,7,8,12,2,3,4,5,9,10,11],
    times: {
      firstClue: {
        time:4,
        format:"04:00"
      },
      nextClue: {
        time:2,
        format:"02:00"
      },
      totalTime: 11
    }
  },
  {
    group: 4,
    cards:[1,5,6,7,8,12,2,3,4,9,10,11],
    times: {
      firstClue: {
        time:4,
        format:"04:00"
      },
      nextClue: {
        time:2,
        format:"02:00"
      },
      totalTime: 11
    }
  },
  { //Test group
    group: 5,
    cards: [11,2],
    times: {
      firstClue: {
        time: 0.1,
        format: "04:00"
      },
      nextClue: {
        time:1,
        format:"02:00"
      },
      totalTime: 11
    }
  },
]


export default groups;
