import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logosvg',
  templateUrl: './logosvg.component.html',
  styleUrls: ['./logosvg.component.css']
})
export class LogosvgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
