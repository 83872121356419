var cards = [
  {
    id: 1,
    clues: [
      "Encontrá el mástil con la bandera Argentina en la Plaza de Mayo",
      "La respuesta está en la placa del mástil",
      "Los cuadrados de la carta representan letras. Los cuadrados pintados de negro representan espacios"
    ]
  },
  {
    id: 2,
    clues: [
      "Obtene de la pirámide un número de 4 dígitos, sumando de a dos dígitos",
      "16 es el último casillero pintado en el suelo. Utiliza los 4 dígitos obtenidos como en un tablero de juego, avanzando sobre los casilleros en el siguiente sentido: 1) como las agujas del reloj - 2) contrario a las agujas del reloj - 3)  como las agujas del reloj - 4) contrario a las agujas del reloj, respectivamente.",
      "Desde el casillero final veras tu próxima parada"
    ]
  },
  {
    id:3,
    clues: [
      "Ingresa",
      "Encontrá el mausoleo entre XI y XII para deducir allí el país faltante",
      "Este país se encuentra bajo tierra cerca de aquí."
    ]
  },
  {
    id:4,
    clues: [
      "Ingresa. Busca los íconos en los carteles de publicidades antiguas",
      "Encontrá las letras faltantes y  llena los cuadrados ubicándolas en orden",
      "La frase no se lee de manera habitual"
    ]
  },
  {
    id:5,
    clues: [
      "Encontrá en tu kit la figura troquelada con la forma del Edificio del Cabildo ",
      "Desliza el troquel sobre las líneas escritas para develar la frase.",
      "Hay una frase de 5 palabras"
    ]
  },
  {
    id:6,
    clues: [
      "Se deben obtener tres palabras de esta carta",
      "La segunda palabra es masculina",
      "La tercer palabra debe ser en español y está relacionada con la imagen"
    ]
  },
  {
    id:7,
    clues: [
      "Las series numéricas forman palabras. Las horizontales se encuentran representadas en el monumento",
      "Usa tu celular para obtener las letras",
      "La palabra clave es la vertical que te servirá en una instancia futura"
    ]
  },
  {
    id:8,
    clues: [
      "Utiliza la foto para descifrar la calle y su numeración y dirígete a ese sitio.",
      "Indaga en el rock nacional",
      "Observa algún elemento que se encuentra en tu ubicación, que junto con el texto, te ayudarán a descubrir la palabra clave."
    ]
  },
  {
    id:9,
    clues: [
      "Utiliza la palabra clave de cada carta previa para llenar tu tarjeta de coordenadas",
      "La numeración vertical se corresponde con el orden de las cartas",
      "Esta carta no te lleva a ningún destino"
    ]
  },
  {
    id:10,
    clues: [
      "Para descifrar tu destino final deberás utilizar este ticket junto a la carta anterior",
      "El código esconde las coordenadas",
      "Utiliza la carta con el mapa para encontrar el destino final"
    ]
  },
  {
    id: 11,
    clues: [
      "El mensaje oculto esconde la frase final.",
      "Las palabras están codificadas en forma de anagramas.",
      "Ingresa la frase decodificada en la web app para ESCAPAR!"
    ]
  },
  {
    id:12,
    clues: [
      "Descifra las 3 palabras a partir de las imágenes",
      "La última imagen encierra dos palabras",
      "La última imagen está relacionada con la Independencia de un país americano"
    ]
  },
  {
    id:17,
    clues: [
      "Tenes un pasaje que te lleva al destino final",
      "Este destino es una ciudad, localizalo en tu kit",
      "El mapa te guiara"
    ]
  }

]

export default cards;
