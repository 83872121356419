import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  code : any;
  private possibleCodes = [
    {
      code: "grupo1",
      group: 1
    },
    {
      code: "grupo2",
      group: 2
    },
    {
      code: "grupo3",
      group: 3
    },
    {
      code: "grupo4",
      group: 4
    },
    {
      code: "testgroup",
      group: 5
    }
  ]

  constructor() { }

  setCode(code: any) {
    this.code = code.toLowerCase();
    localStorage.setItem("group", ""+this.possibleCodes.find(g => g.code == this.code.toLowerCase()).group);
  }

  getGroup() {
    return parseInt(localStorage.getItem("group"))
  }

  isValidCode(code: any) {
    if (!code) return false;
    return this.possibleCodes.find(groupCode => groupCode.code == code.toLowerCase());
  }
}
