import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-final-answer',
  templateUrl: './final-answer.component.html',
  styleUrls: ['./final-answer.component.css']
})
export class FinalAnswerComponent implements OnInit {

  answer: any = "";
  won = false;

  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }

  onSubmit() {
    var parsedAnswer = this.answer.toLowerCase().replace(",", "")
    const expected =  "estas a salvo escapaste de la plaga"
    const distance = this.levenshteinDistance(parsedAnswer, expected);
    const expected2 = "escapaste de la plaga estas a salvo"
    const distance2 = this.levenshteinDistance(parsedAnswer, expected2)
    if (distance < 7 || distance2 < 7) {
      this.won = true;
    } else {
      this.toastr.error('Respuesta incorrecta, intenta de nuevo', '', {
        positionClass: 'toast-bottom-right'
      })
    }
  }

  levenshteinDistance (a, b) {
    const an = a ? a.length : 0;
	const bn = b ? b.length : 0;
	if (an === 0)
	{
		return bn;
	}
	if (bn === 0)
	{
		return an;
	}
	const matrix = new Array<number[]>(bn + 1);
	for (let i = 0; i <= bn; ++i)
	{
		let row = matrix[i] = new Array<number>(an + 1);
		row[0] = i;
	}
	const firstRow = matrix[0];
	for (let j = 1; j <= an; ++j)
	{
		firstRow[j] = j;
	}
	for (let i = 1; i <= bn; ++i)
	{
		for (let j = 1; j <= an; ++j)
		{
			if (b.charAt(i - 1) === a.charAt(j - 1))
			{
				matrix[i][j] = matrix[i - 1][j - 1];
			}
			else
			{
				matrix[i][j] = Math.min(
					matrix[i - 1][j - 1], // substitution
					matrix[i][j - 1], // insertion
					matrix[i - 1][j] // deletion
				) + 1;
			}
		}
	}
	return matrix[bn][an];
  }

}
