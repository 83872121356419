import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-lost',
  templateUrl: './game-lost.component.html',
  styleUrls: ['./game-lost.component.css']
})
export class GameLostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
