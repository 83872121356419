import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { TimerComponent } from './timer/timer.component';
import { HeaderComponent } from './header/header.component';
import { GameComponent } from './game/game.component';
import { GameLostComponent } from './game-lost/game-lost.component';
import { FinalAnswerComponent } from './final-answer/final-answer.component';
import { VerifyZohoComponent } from './verify-zoho/verify-zoho.component';
import { GamestateGuard } from './gamestate.guard'

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [GamestateGuard] },
    { path: 'game', component: GameComponent, canActivate: [GamestateGuard] },
    { path: 'game-lost', component: GameLostComponent },
    { path: 'final-answer', component: FinalAnswerComponent, canActivate: [GamestateGuard] },
    { path: 'zohoverify/verifyforzoho.html', component: VerifyZohoComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
