import { Component, OnInit } from '@angular/core';
import { GameService } from '../game.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-game-timer',
  templateUrl: './game-timer.component.html',
  styleUrls: ['./game-timer.component.css']
})
export class GameTimerComponent implements OnInit {

  display: any;

  constructor(private gameService: GameService, private router: Router) {
    gameService.startGame()
    gameService.getObservable().subscribe(notif => {
      this.display = notif.display;
      if (notif.lost) {
        this.router.navigate(['/game-lost'])
      } else {
        if (notif.timeLeft < 10 * 60) {
          document.getElementById("gametimecontainer").style.color = "#ee0202"
        } else if (notif.timeLeft < 30 * 60) {
          document.getElementById("gametimecontainer").style.color = "#eeee02"
        }
      }
    })
  }

  ngOnInit() {

  }



}
