import { Injectable} from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private eventStream = new Subject();
  private clearSubject = new Subject();

  lastTime: any;
  count: any;
  countDown: any;
  display: any;
  groupId: any;

  constructor() {
    this.groupId = parseInt(localStorage.getItem("group")) - 1;
  }

  newClue(clue: any) {
    this.eventStream.next(clue)
  }

  newDisplay(display: string) {
    this.eventStream.next(display);
  }

  clearClues() {
    this.clearSubject.next({clear:true})
  }

  getObservable(): Observable<any> {
      return this.eventStream.asObservable();
  }

  getClearObservable(): Observable<any> {
      return this.clearSubject.asObservable();
  }

  createTimer(clueList: any, clueIndex: any, duration: any, initDisplay: any) {
    this.display = initDisplay;
    this.countDown = null;
    this.count = null;

    if (duration > 0)
      this.newDisplay(initDisplay);

    var storedTimer = localStorage.getItem("timer"+this.groupId);

    if (storedTimer)
      duration = parseInt(storedTimer);

    var timer = duration, minutes, seconds;

    this.lastTime = new Date();

    var interval = duration > 0 ? 1000 : 0;

    this.countDown = setInterval(() => {
      var timerDiff = this.getTimerDiff();
        if (timerDiff > timer) {
          if (((timer - timerDiff) * -1) > 60*2) {
            this.newClue({clue: clueList[clueIndex+1]})
          }
          if (((timer - timerDiff) * -1) > 60*4) {
            this.newClue({clue: clueList[clueIndex+2]})
          }
        }
        timer-=timerDiff;
        minutes = parseInt(""+(timer / 60), 10)
        seconds = parseInt(""+(timer % 60), 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        if (duration > 0) {
          this.display = minutes + ":" + seconds;
          this.newDisplay(this.display);
        }
        localStorage.setItem("timer"+this.groupId, timer)
        if (--timer < 0) {
          localStorage.removeItem("timer"+this.groupId)
          this.newClue({clue: clueList[clueIndex]})
        }
    }, interval);
  }

  getTimerDiff() {
    var actualTime = new Date();
    var diffSeconds = Math.abs(Math.round((this.lastTime.getTime() - actualTime.getTime())/1000));
    if (diffSeconds > 3) {
      this.lastTime= new Date();
      return diffSeconds;
    } else {
      this.lastTime = new Date();
      return 0;
    }
  }

  getDisplayForInterval(timer) {

  }
}
