import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private eventStream = new Subject();

  lastTime: any;
  count: any;
  countDown: any;
  display: any;

  constructor() { }

  getObservable(): Observable<any> {
      return this.eventStream.asObservable();
  }

  gg() {
    this.eventStream.next({display:"00:00", lost:true})
  }

  newDisplay(display: string, timer: any) {
    this.eventStream.next({display:display, lost:false, timeLeft: timer});
  }

  startGame() {
    var duration = 60*60*2;
    this.display = "2:00:00";
    this.countDown = null;
    this.count = null;
    var groupId = parseInt(localStorage.getItem("group")) - 1;


    var storedTimer = localStorage.getItem("gametimer"+groupId);

    if (storedTimer)
      duration = parseInt(storedTimer);

    var timer = duration, hours, minutes, seconds;
    this.newDisplay(this.display, timer);


    this.lastTime = new Date();
    this.countDown = setInterval(() => {
        timer-=this.getTimerDiff();
        hours = parseInt(""+(timer / 3600), 10)
        if (timer > 3600) {
          minutes = parseInt(""+(timer / 60 % 60), 10)
        } else {
          minutes = parseInt(""+(timer / 60), 10)
        }
        seconds = parseInt(""+(timer % 60), 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.display = hours + ":" + minutes + ":" + seconds;
        this.newDisplay(this.display, timer);
        localStorage.setItem("gametimer"+groupId, ""+timer)
        if (--timer < 0) {
          this.gg()
        }
    }, 1000);
  }

  getTimerDiff() {
    var actualTime = new Date();
    var diffSeconds = Math.abs(Math.round((this.lastTime.getTime() - actualTime.getTime())/1000));
    if (diffSeconds > 3) {
      this.lastTime= new Date();
      return diffSeconds;
    } else {
      this.lastTime = new Date();
      return 0;
    }
  }
}
