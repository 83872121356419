import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-zoho',
  template: '1530744680548'
})
export class VerifyZohoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
