import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Observable } from "rxjs/observable";
import { TimerService } from '../timer.service'
import { CodeService } from '../code.service'
import { ConfirmNextCardComponent } from '../confirm-next-card/confirm-next-card.component'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Router } from '@angular/router';
import cards from '../jsons/cards'
import groups from '../jsons/groups'
import 'rxjs/';


@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

  display: any;
  cardList: any;
  clueList: any;
  groupId: any;
  currentCard: any;
  cardsAmount: any = 0;
  buttonMessage: string;
  currentClue: any;
  showButton: any;

  constructor(private timerService :TimerService, private codeService: CodeService, private router: Router, private dialog : MatDialog) {
    this.groupId = codeService.getGroup()-1;

    this.restoreSession()
    let group = this.getGroup();
    this.cardList = this.getCardsForGroup(group);

    this.setButtonName()
    this.checkFinished()


    this.clueList = this.getCardClues()

    if (!localStorage.getItem("showsButton"+this.groupId) || localStorage.getItem("showsButton"+this.groupId) == 'false') {
      this.showButton= false;
      //Checkea en base a la sesion que timer mostrar
      console.log(this.currentCard);
      console.log(this.cardsAmount);

      if (!this.isLastCards()) {
        if (this.currentClue == 0)
          this.updateTime(this.clueList, this.getNextClueIndex(), this.getFirstClueTime().time*60, this.getFirstClueTime().time);
        else
          this.updateTime(this.clueList, this.getNextClueIndex(), this.getNextClueTime().time*60, this.getNextClueTime().format);
      }
      else {
        this.updateTime(this.clueList, this.getNextClueIndex(), 0, '00:00');
      }

    } else if (!this.isLastCards()) {
      this.showButton = true;
    }

    timerService.getObservable().subscribe(
      message =>  {
        if (this.checkConcurrency(this.currentCard)) {
          location.reload()
        } else {
          if (message instanceof Object){
            clearInterval(this.timerService.countDown)
            this.currentClue++
            this.setCurrentClue(this.currentClue)
            localStorage.setItem("showsButton"+this.groupId, "false");
            var nextClue = this.getNextClue()
            if (this.currentCard >= this.cardsAmount-1) {
              this.updateTime(this.clueList, this.getNextClueIndex(), 0, '00:00')
            } else {
              if (nextClue.clue) {
                this.updateTime(this.clueList, this.getNextClueIndex(), this.getNextClueTime().time*60, this.getNextClueTime().format)
              } else {
                this.setButtonName()
                this.showButton = !this.isLastCards();
                localStorage.setItem("currentCard"+this.groupId, this.currentCard);
                localStorage.setItem("currentClue"+this.groupId, this.currentClue);
                localStorage.setItem("showsButton"+this.groupId, this.showButton);
              }
            }
          }
          else {
            this.display = message
          }
        }
      }
    )
  }

  ngOnInit() {

  }

  isLastCards() {
    return this.currentCard >= this.cardsAmount-1
  }

  setCurrentClue(clue) {
    var stored = localStorage.getItem("currentClue"+this.groupId)
    if (stored && parseInt(stored) > clue) {
      this.restoreSession()
      this.router.navigate(['/game'])
    }
    else
    localStorage.setItem("currentClue"+this.groupId, clue);
  }

  restoreSession() {

    var storedClue = localStorage.getItem("currentClue"+this.groupId);
    var storedCard = localStorage.getItem("currentCard"+this.groupId);
    if (storedCard)
      this.currentCard = parseInt(storedCard);
    else
      this.currentCard = 0;

    if (storedClue)
      this.currentClue = parseInt(storedClue);
    else
      this.currentClue = 0;
  }

  startNextCard() {
      let dialogRef = this.dialog.open(ConfirmNextCardComponent, {
        data: {cardNumber: this.currentCard+1}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.nextState();
        }
      });
  }

  checkConcurrency(card) {
    var stored = localStorage.getItem("currentCard"+this.groupId)
    var storedClue = localStorage.getItem("currentClue"+this.groupId)
    return (stored && parseInt(stored) > card) || (storedClue && storedClue != this.currentClue)
  }

  nextState() {
    clearInterval(this.timerService.countDown)
    if (this.checkConcurrency(this.currentCard)) {
      var clueList = localStorage.getItem("clueList"+this.groupId);
      this.timerService.clearClues()

      if (clueList) {
        var json = JSON.parse(clueList)
        for (var i = 0 ; i < json.length; i++) {
          this.timerService.newClue(json[i])
        }
      }

      location.reload()
    } else {
      this.currentClue = 0;
      this.currentCard++;
      localStorage.setItem("currentCard"+this.groupId, this.currentCard)
      localStorage.setItem("showsButton"+this.groupId, "false")
      localStorage.setItem("currentClue"+this.groupId, this.currentClue)
      localStorage.removeItem("timer"+this.groupId)
      this.checkFinished();
      this.showButton = false;
      this.clueList = this.getCardClues()

      //Check if first clue
      if (this.currentCard <= this.cardsAmount)
        this.updateTime(this.clueList, this.getNextClueIndex(), this.getFirstClueTime().time*60, this.getFirstClueTime().format)
      else
        this.updateTime(this.clueList, this.getNextClueIndex(), 0, '00:00')

      this.timerService.clearClues()
      this.setButtonName()
    }
  }

  setButtonName() {
    if (this.showFinishButton()) {
      this.buttonMessage = "Completar última carta"
    } else {
      this.buttonMessage = "IR A CARTA #" + (this.currentCard+2)
    }
  }

  getCardClues() {
    return this.cardList[this.currentCard].clues;
  }

  getGroup() {
    return groups[this.groupId];
  }

  getFirstClueTime() {
    return groups[this.groupId].times.firstClue
  }

  getNextClueTime() {
    return groups[this.groupId].times.nextClue
  }

  getCardsForGroup(group: any) {
    var groupcards = group.cards
    var foundCards = []
    for (let card in groupcards) {
      var found = cards.find(c => c.id == groupcards[card])
      foundCards.push(found);
      this.cardsAmount++;

    }
    return foundCards;
  }

  showFinishButton() {
    return this.currentCard + 1 >= this.cardsAmount;
  }

  finished() {
    return this.currentCard + 1 > this.cardsAmount;
  }

  checkFinished() {
    if (this.finished()) {
      this.router.navigate(['/final-answer'])
    }
  }

  getNextClue() {
    var clueToReturn = {clue: this.clueList[this.currentClue]};
    return clueToReturn;
  }

  getNextClueIndex() {
    return this.currentClue;
  }

  updateTime(clueList, clueIndex, time, display) {
    this.timerService.createTimer(clueList, clueIndex, time, display);
  }
}
